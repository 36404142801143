import { useRef } from "react";
import useIsomorphicLayoutEffect from "../useIsomorphicLayoutEffect";

const useNotificationSound = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useIsomorphicLayoutEffect(() => {
    audioRef.current = new Audio("/sounds/notification-alert.m4a");
  }, []);

  const play = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  const stop = () => {
    if (audioRef.current) {
      audioRef.current.play();
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return {
    play,
    stop,
  };
};

export default useNotificationSound;
